// 系统日志信息
import request from '@/network/request.js'
/**
 * 用户的管理列表信息
 */
export function loglists(data) {
	return request({
		url: '/admin/system/lists_loginfo',
		method: 'post',
		data,
	})
}
/**
 * 删除/批量日志信息
 **/
export function del_log(data) {
	return request({
		url: '/admin/system/del_loginfo',
		method: 'post',
		data,
	})
}

/**
 * 清空日志
 ***/
export function emptylog(data) {
	return request({
		url: '/admin/system/emptylog',
		method: 'post',
		data,
	})
}


