<template>
	<div class="wrap">
		<!--用户管理 -->
		<!-- 头部 -->
		<el-form ref="queryForm" :model="queryParams" size="small" tooltip-effect="dark" :inline="true">
			<el-form-item label="人员昵称:">
				<el-input v-model="queryParams.user_name" clearable style="width: 200px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="人员账号:">
				<el-input v-model="queryParams.account" clearable style="width: 200px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="ip:">
				<el-input v-model="queryParams.ip" clearable style="width: 200px" @keyup.enter.native="handleQuery" />
			</el-form-item>

			<el-form-item label="ip地址:">
				<el-input v-model="queryParams.address" clearable style="width: 200px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>

			<el-form-item label="系统模块:">
				<el-input v-model="queryParams.module" clearable style="width: 200px"
					@keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item label="时间">
				<el-date-picker v-model="dateRange" style="width: 240px" value-format="yyyy-MM-dd" type="daterange"
					range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>
		<!-- 新增按钮 -->
		<el-row :gutter="10">
			<!-- 			<el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
			</el-col> -->
			<el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="deletedata">删除</el-button>
			</el-col>

			<el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="emptyAll">清空</el-button>
			</el-col>

			<el-col :span="1.5">
				<el-button type="warning" plain icon="el-icon-download" size="mini" @click="countDowndata">导出
				</el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="warning" plain icon="el-icon-refresh-left" size="mini" @click="handshua">刷新</el-button>
			</el-col>
		</el-row>
		<!-- 表格信息 -->
		<el-table v-loading="loading" :lazy="true" :data="tableData" style="width: 100%"
			:header-cell-style="{textAlign: 'center'}" :cell-style="{textAlign: 'center'}"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="id" sortable label="id" />
			<el-table-column prop="account" sortable label="账号" />
			<el-table-column prop="user_name" sortable label="昵称" />
			<el-table-column prop="module" label="系统模块" />
			<el-table-column prop="operatetyppe" label="请求方式" />
			<el-table-column prop="pathfile" label="请求路径" />
			<el-table-column prop="address" label="操作地点" />
			<el-table-column prop="ip" label="操作地址" />
			<el-table-column prop="startinfo" label="状态" />
			<el-table-column prop="createdAt" sortable label="创建时间" width="200" />
			<el-table-column label="操作" width="200" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="success" @click="handleMember(scope.row)">查看</el-button>
					<!-- 					<el-button size="mini" type="warning" @click="startUsingOrFreeze(scope.row)">
						{{scope.row.status == 1 ? '冻结' : '正常' }}
					</el-button> -->
					<el-popconfirm style="margin-left: 10px;" confirm-button-text='确定' cancel-button-text='不用了'
						icon="el-icon-info" icon-color="red" title="确定要删除吗？" @confirm="handleDelete(scope.row.id)">
						<el-button size="mini" type="danger" slot="reference">删除</el-button>
						<!-- <el-button slot="reference">删除</el-button> -->
					</el-popconfirm>
					<!-- <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button> -->
				</template>

			</el-table-column>
		</el-table>
		<!-- 完整功能分页 -->
		<el-pagination :current-page="currentPage" :page-sizes="[10, 15, 30, 50, 100]" :page-size="queryParams.pageSize"
			layout="->, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" />
		<el-dialog title="详情" :visible.sync="dialogFormVisible" width="50%">
			<el-form ref="form" :model="form">
				<!-- 用户名称 -->
				<el-form-item label="日志编号:" :label-width="formLabelWidth" prop="name">
					{{form.log_num}}
				</el-form-item>

				<el-form-item label="系统模块:" :label-width="formLabelWidth" prop="name">
					{{form.module}}
				</el-form-item>

				<el-form-item label="请求方式:" :label-width="formLabelWidth" prop="name">
					{{form.request_method}}
				</el-form-item>

				<el-form-item label="操作类型:" :label-width="formLabelWidth" prop="name">
					{{form.oper_type}}
				</el-form-item>

				<el-form-item label="操作人员:" :label-width="formLabelWidth" prop="name">
					{{form.operator}}
				</el-form-item>

				<el-form-item label="操作地址:" :label-width="formLabelWidth" prop="name">
					{{form.oper_address}}
				</el-form-item>

				<el-form-item label="操作状态:" :label-width="formLabelWidth" prop="name">
					{{form.oper_status}}
				</el-form-item>


				<el-form-item label="操作信息:" :label-width="formLabelWidth" prop="name">
					{{JSON.stringify(form.content)}}
				</el-form-item>

				<el-form-item label="创建时间:" :label-width="formLabelWidth" prop="name">
					{{form.create_time}}
				</el-form-item>


			</el-form>
			<!--      <div slot="footer" class="dialog-footer">
			        <el-button type="danger" @click="cancel">取消</el-button>
			        <el-button type="primary" @click="confirm">确定</el-button>
			      </div> -->
		</el-dialog>

	</div>
</template>

<script>
	// 系统用户相关的
	const {
		loglists, //日志的分页列表
		del_log, //删除/批量删除日志
		emptylog, //清空日志
	} = require("@/api/systemlog");
	// 角色相关的
	const {
		role_listAll,
	} = require("@/api/role");
	export default {
		data() {
			// 自定义 tree 规则
			const validatePass = (rule, value, callback) => {
				const arr = this.$refs.menu.getCheckedKeys()
				if (arr.length === 0 || !arr) {
					callback(new Error('请选择功能权限设置'))
				} else {
					callback()
				}
			}
			return {
				// 进来加载表单的样式
				loading: true,
				registerForm: {
					// 剧本名
					jubenname: '',
				},
				configurationstart: false,
				// 顶部表单需要的数据
				queryParams: {
					// 页码
					pageNo: 1,
					// 每页显示多少条
					pageSize: 10,
					user_name: '', //操作人员昵称
					start_time: '', //开始时间
					end_time: '', //结束时间
					module: '', //系统模块
					account: '', //账号
					ip: '', //ip
					address: '', //ip的位置
				},
				// 选择器 数据
				options: [{
					id: 1,
					value: 1,
					name: '启用'
				}, {
					id: 2,
					value: 2,
					name: '冻结'
				}],
				// 日期范围
				dateRange: [],
				// 表格数据
				tableData: [],
				// form 表单的lable属性 宽度
				formLabelWidth: '110px',
				// 数据总数量
				total: null,
				// 分页组件数据
				currentPage: 1,
				// 角色列表
				roleList: [],
				// 弹窗里面的 表单数据
				menuExpand: false,
				menuNodeAll: false,
				// 菜单列表
				menuOptions: [],
				// 表单参数
				form: {
					// 账号状态
					status: 1,
					// 用户名称
					name: "",
					// 登陆账号
					account: "",
					// 手机号
					phone: "",
					// 密码
					password: "",
					// 角色id
					role_id: 0,
				},
				defaultProps: {
					children: 'menu_list',
					label: 'name'
				},
				// 单选框 数据
				radio: null,
				// 是否显示 添加/修改弹窗
				dialogFormVisible: false,
				// 判断是新增 还是 修改
				flag: false,
				menu_access: []
			}
		},
		watch: {
			dateRange: function() {
				this.queryParams.start_time = this.dateRange && this.dateRange[0] || undefined
				this.queryParams.end_time = this.dateRange && this.dateRange[1] || undefined
				this.handleQuery()
			},
			// 系统模块
			'queryParams.module': function() {
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {
					this.handleQuery()
				}, 700);
			},
			// 操作人员昵称
			'queryParams.user_name': function() {
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {
					this.handleQuery()
				}, 700);
			},
			// 操作人员账号
			'queryParams.account': function() {
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {
					this.handleQuery()
				}, 700);
			},
			// ip地址
			'queryParams.address': function() {
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {
					this.handleQuery()
				}, 700);
			},
			// ip
			'queryParams.ip': function() {
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {
					this.handleQuery()
				}, 700);
			}
		},
		created() {
			// 调用 封装好的 角色列表接口函数
			this.getRoleLists()
		},
		methods: {
			// 清空所有的信息
			async emptyAll() {
				this.$confirm('此操作将永久清空记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let emptylogres = await emptylog();
					emptylogres.code == 1 ? this.$message({
						type: 'success',
						message: emptylogres.msg
					}) : '';
					this.handshua()
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消清空'
					});
				});
			},

			// 点击了配置这个选项
			configurationstartButto(e) {
				console.log(e, this.configurationstart)
				this.configurationstart = !this.configurationstart;
			},
			//导出的操作
			async countDowndata() {
				// console.log("路径是：",`/admin/system/export_excel?user_name=${this.queryParams.user_name}&&start_time=${this.queryParams.start_time}&&end_time=${this.queryParams.end_time}&&module=${this.queryParams.module}&&account=${this.queryParams.account}&&ip=${this.queryParams.ip}&&address=${this.queryParams.address}`)
				
				window.location.href =
					`/admin/system/export_excel?user_name=${this.queryParams.user_name}&&start_time=${this.queryParams.start_time}&&end_time=${this.queryParams.end_time}&&module=${this.queryParams.module}&&account=${this.queryParams.account}&&ip=${this.queryParams.ip}&&address=${this.queryParams.address}`
			},
			// 执行删除信息
			async deletedata() {
				this.$confirm('此操作将永久删除记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					if (!this.Arraydelete) {
						this.$message.error("请选择你要删除的信息~~")
						return;
					}
					this.handleDelete(this.Arraydelete);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},

			// 获取删除的选项的信息
			handleSelectionChange(val) {
				let deleteArrayS = ""
				val.forEach((item, index) => {
					if (deleteArrayS == "") { //说明是空的
						deleteArrayS += item.id
					} else {
						deleteArrayS += "," + item.id
					}
				})
				this.Arraydelete = deleteArrayS
			},

			// 点击 弹窗里面的 展开/折叠 触发的函数    树权限（展开/折叠）
			handleCheckedTreeExpand(value) {
				const treeList = this.menuOptions
				for (let i = 0; i < treeList.length; i++) {
					this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value
				}
			},
			// 点击刷新按钮
			async handshua() {
				await this.getRoleLists();
				this.$message.success('刷新成功~')
			},

			// 点击 弹窗里面的 全选/全不选 触发的函数  树权限（全选/全不选）
			handleCheckedTreeNodeAll(value) {
				this.$refs.menu.setCheckedNodes(value ? this.menuOptions : [])
			},

			// 点击 弹窗里面的 父子联动 触发的函数 树权限（父子联动）
			handleCheckedTreeConnect(value) {
				this.form.menuCheckStrictly = value
			},

			// 封装 角色列表接口函数
			async getRoleLists() {
				this.loading = true;
				console.log(this.queryParams)
				const {
					data
				} = await loglists(this.queryParams)
				this.tableData = data.rows
				this.total = data.count
				this.loading = false;
				// this.tableData = [{
				// 	log_num: "12",
				// 	module:"fewgv "
				// }]
			},

			// 封装 修改角色状态接口函数
			// async srole_status(data) {
			// 	await srole_status(data)
			// 	this.getRoleLists()
			// },

			// 封装 角色权限接口函数 /全部的角色信息
			async role_permission() {
				const {
					data
				} = await role_listAll()
				this.menuOptions = data
			},

			// 封装 根据表id查询角色
			async get_role(obj) {
				const {
					data
				} = await get_role(obj)
				this.form = {
					...this.form,
					...data
				}
			},

			// 封装 添加修改角色接口函数
			async add_or_edit_role(obj) {
				let usersaveres = await user_save(obj);
				usersaveres.code == 1 ? this.$message({
					type: 'success',
					message: this.flag ? '新增成功~~' : '修改成功~~'
				}) : ''
				// 修改的时候 调用
				// if (!this.flag) {
				// 	// this.menu_access 是修改过后的  选中的节点数据，  长度大于0  说明 在点击确认之前，进行过修改节点状态， 反之 没有修改节点状态
				// 	if (this.menu_access.length > 0) {
				// 		await add_or_edit_role({
				// 			id: obj.id,
				// 			name: obj.name,
				// 			status: obj.status,
				// 			menu_access: obj.menu_access.join(',')
				// 		})
				// 		// 调用 封装好的 角色列表接口函数
				// 		this.getRoleLists()
				// 		this.menu_access.length = 0
				// 	} else {
				// 		await add_or_edit_role(this.form)
				// 		// 调用 封装好的 角色列表接口函数
				// 		this.getRoleLists()
				// 	}
				// } else {
				// 	// 新增 的时候 调用
				// 	const form = this.form
				// 	await this.handleCheckChange()
				// 	await add_or_edit_role({
				// 		...form,
				// 		menu_access: this.menu_access.join(',')
				// 	})
				// 	// 调用 封装好的 角色列表接口函数
				// 	this.getRoleLists()
				// }
			},

			// 封装 删除角色 接口函数
			async del_log(data) {
				// 说明是当页最后一个 且不是第一页
				if (this.tableData.length == 1 && this.queryParams.pageNo > 1) {
					this.queryParams.pageNo--
				}

				let del_log_res = await del_log(data);
				del_log_res.code == 1 ? this.$message({
					type: 'success',
					message: "删除成功~~"
				}) : ''
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()

				// 在删除表单最后一条数据时,自动返回上一页,但实际情况是还是删除前的页数,数据为空
				/**
				 *    this.total： 总数据条数
				 *    this.queryParams.pageSize： 每页多少条数据
				 *    this.queryParams.pageNo： 当前页码(当前是哪一页)
				 */
				const totalPage = Math.ceil((this.total - 1) / (this.queryParams.pageSize ? this.queryParams.pageSize :
					10))
				this.queryParams.pageNo = this.queryParams.pageNo > totalPage ? totalPage : this.queryParams.pageNo
				this.queryParams.pageNo = this.queryParams.pageNo < 1 ? 1 : this.queryParams.pageNo
			},

			// 顶部 新增按钮  点击触发的函数
			async handleAdd() {
				// 重置 弹窗里面的 表单数据 --开始--
				this.form = {

				}
				this.menuExpand = false
				this.menuNodeAll = false
				// 菜单列表
				this.menuOptions = []
				// 重置 弹窗里面的 表单数据 --结束--
				// 判断是新增
				this.flag = true
				// 调用 封装好的 角色权限 函数
				// await this.role_permission()
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible
			},

			// 点击操作里 修改按钮 触发的函数
			async handleEdit(row) {
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible;
				this.form = row;

			},

			// 点击操作里 启动/冻结 按钮 触发的函数
			async startUsingOrFreeze(obj) {

				let status_set = obj.status == 1 ? 2 : 1;
				let statusres = await srole_status({
					id: obj.id,
					status: status_set
				})
				statusres.code == 1 ? this.$message({
					type: 'success',
					message: status_set == 1 ? '解冻成功' : '冻结成功'
				}) : ''
				this.getRoleLists()
			},

			// 在弹窗dialog里面的 tree 树形结构的 节点选中状态发生变化时的回调
			handleCheckChange() {
				// 获取所有选中的节点 start
				const res = this.$refs.menu.getCheckedNodes()
				this.menu_access = []
				res.forEach((item) => {
					// this.menu_access.push(item.id)
				})
				// 获取所有选中的节点 end
			},

			// 点击操作里 成员按钮 触发的函数
			async handleMember(form) {
				// this.handleAdd()
				// 重置 弹窗里面的 表单数据 --开始--
				this.form = form;
				this.menuExpand = false
				this.menuNodeAll = false
				// 菜单列表
				this.menuOptions = []
				// 重置 弹窗里面的 表单数据 --结束--
				// 判断是新增
				this.flag = false
				// 调用 封装好的 角色权限 函数
				// await this.role_permission()
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible
			},

			// 点击操作里 删除按钮 触发的函数
			handleDelete(id) {
				// 调用 封装好的 删除角色 接口函数
				this.del_log({
					ids: id
				})
			},

			// 点击查询按钮 触发的函数
			handleQuery() {
				this.getRoleLists()
			},

			// 点击重置按钮 触发的函数
			resetQuery() {
				// 重置数据
				this.queryParams = {
						operator: '', //操作人员
						start_time: '', //开始时间
						end_time: '', //结束时间
						module: '', //系统模块
					},

					this.dateRange = [];
				// 如果重置效果也需要数据回到最初状态，则直接调用 角色列表接口函数
				this.getRoleLists()
			},

			// 弹窗里面的取消按钮
			cancel() {
				this.dialogFormVisible = !this.dialogFormVisible
				this.flag = false
				// 重置数据
				this.form = {

				}
				this.$nextTick(() => {
					// 移除表单校验效果
					this.$refs.form.clearValidate()
				})
			},

			// 弹窗里面的确认按钮
			async confirm() {


				// 添加角色 触发
				// 表单校验
				await this.$refs.form.validate()
				// 调用 添加/修改 角色接口函数
				await this.add_or_edit_role(this.form)
				// 移除表单校验效果
				this.$refs.form.clearValidate()
				// 修改角色 触发
				// if (!this.flag) {
				// 	// 表单校验
				// 	await this.$refs.form.validate()
				// 	// 调用 添加/修改 角色接口函数
				// 	this.add_or_edit_role({
				// 		...this.form
				// 		// menu_access: this.menu_access
				// 	})
				// 	// 移除表单校验效果
				// 	this.$refs.form.clearValidate()
				// } else if (this.flag) {
				// 	// 添加角色 触发
				// 	// 表单校验
				// 	await this.$refs.form.validate()
				// 	// 调用 添加/修改 角色接口函数
				// 	this.add_or_edit_role(this.form)
				// 	// 移除表单校验效果
				// 	this.$refs.form.clearValidate()
				// }
				// 刷新页面
				await this.getRoleLists();
				this.dialogFormVisible = !this.dialogFormVisible;
				this.flag = false
				// 重置数据
				this.form = {
					menuCheckStrictly: false
				}
			},

			// 点击切换 每页几条数据时 触发的函数
			handleSizeChange(val) {
				// 把切换的每页页数 重新赋值给 this.queryParams.pageSize
				this.queryParams.pageSize = val
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()
			},
			// 更改 前进几页 数据时 触发的函数
			handleCurrentChange(val) {
				// 把更改的页码 重新赋值给 this.queryParams.pageNo
				this.queryParams.pageNo = val
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()
			},

			// element ui 表格 格式化 函数
			formatStateCompany(row, column, cellValue) {
				if (cellValue === 1) {
					return '启用'
				} else if (cellValue === 2) {
					return '冻结'
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.wrap {
		margin: 20px;

		// 分页组件 距离上边的距离
		.el-pagination {
			margin-top: 20px;
		}
	}

	// 表头最外边框
	::v-deep .el-table__header {
		margin-top: 20px;
		border: 1px solid #ebeef5;
	}

	.dialog-footer {
		display: flex;
		justify-content: flex-end;
	}
</style>
